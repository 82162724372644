<template>
  <v-container>
    <div class="text-center mt-12" v-if="loading">
      <v-progress-circular size="60" indeterminate color="primary" />
    </div>
    <center v-if="!loading">
      <v-card width="450" class="justify-center text-center">
        <div class="px-3 px-md-10 py-8">
          <v-avatar width="170" height="110" tile class="mb-5">
            <img src="@/assets/images/MVO.png" alt="" />
          </v-avatar>
          <!-- <h3 class="mb-2 text-center">MUTUE VENDAS ONLINE</h3> -->
          <!-- <h5
            class="font-600 grey--text text--darken-3 text-sm mb-9 text-center"
          >
            Login com email e password
          </h5> -->
          <p class="text-14 mb-1 text-left">Nome de Utilizador</p>
          <v-text-field outlined dense hide-details class="mb-4" placeholder="Nome de Utilizador" v-model="user.name"></v-text-field>

          <!-- <p class="text-14 mb-1 text-left">Último Nome</p>
          <v-text-field outlined dense hide-details class="mb-4" placeholder="Nome Completo" v-model="user.last_name"></v-text-field> -->

          <p class="text-14 mb-1 text-left">Telefone</p>
          <v-text-field outlined dense hide-details class="mb-4" placeholder="923 11 22 33" v-model="user.telefone"></v-text-field>

          <p class="text-14 mb-1 text-left">Email</p>
          <v-text-field outlined dense hide-details placeholder="example@mail.com" class="mb-4" v-model="user.email"></v-text-field>

          <!-- <v-alert dense outlined type="error" v-if="validarSenha()">
            Password deve ter 8 Caracteres,uma Letra, um numero e um caracter especial
          </v-alert> -->

          <p class="text-14 mb-1 text-left">Password</p>

          <v-text-field outlined dense hide-details placeholder="Password" :append-icon="showpassword ? 'mdi-eye-off' : 'mdi-eye'" :type="showpassword ? 'text' : 'password'" @click:append="showpassword = !showpassword" @input="validarSenha" class="mb-4" v-model="user.password1"></v-text-field>

          <p class="text-14 mb-1 text-left">Confirmar Password</p>

          <v-text-field outlined dense hide-details placeholder="Confirmar Password" @input="validarSenha" :append-icon="showpassword ? 'mdi-eye-off' : 'mdi-eye'" :type="showpassword ? 'text' : 'password'" @click:append="showpassword = !showpassword" class="mb-4" v-model="user.password2"></v-text-field>

          <!-- <h1>
            {{ user.password1.length }}
          </h1>
          <h1>
            {{ user.password2.length }}
          </h1> -->
          <!-- {{ checkPassword() }} -->
          <v-alert dense outlined type="error" v-if="checkPassword()">
            Passwords não coincidem
          </v-alert>
          <!-- || user.password1.length==0 || user.password2.length==0 -->
          <v-btn block color="secondary" class="text-uppercase font-600 transicao-btn" @click="showDialogValidateEmail()" :disabled="checkPassword()">
            Criar conta</v-btn>
          <v-alert style="color: green" outlined dismissible transition="scale-transition" text dense v-if="alert.type == 'success'" type="success" align="center">
            {{ alert.text }}
          </v-alert>
          <v-alert style="color: red" dismissible transition="scale-transition" outlined dense v-if="alert.type == 'error'" type="error">
            {{ alert.text }}
          </v-alert>
          <v-alert style="color: red" dismissible transition="scale-transition" outlined dense v-if="alert.type == 'error'" type="error">
            {{ errorTelefoneJaExiste }}
          </v-alert>
          <!-- 
          <v-col cols="10" lg="8" class="mx-auto">
            <div class="d-flex align-center my-1">
              <v-divider></v-divider>
              <span class="mx-4">on</span>
              <v-divider></v-divider>
            </div>
          </v-col>
          <v-btn
            block
            dark
            color="indigo"
            class="text-capitalize font-600 mb-4"
          >
            <v-icon left class="me-3">mdi-facebook</v-icon>
            Continuar com facebook
          </v-btn> -->
          <!-- <v-btn
                      block
                      dark
                      color="blue darken-2"
                      class="text-capitalize font-600 mb-4"
                    >
                      <v-icon left class="me-3">mdi-google</v-icon>
                      Continue com Google
                    </v-btn> -->
          <div class="text-14 text-center my-3">
            já tem conta?
            <router-link :to="{ name: 'PortalLogin' }" class="grey--text text--darken-4 font-600">Entrar na minha conta</router-link>
          </div>
        </div>
        <!-- <div class="py-4 grey lighten-2">
          <div class="text-center">
            <span class="grey--text text--darken-1"
              >Esqueci minha Password
              <router-link
                to="/"
                class="ms-2 grey--text text--darken-4 font-600"
                >Recuperar!</router-link
              >
            </span>
          </div>
        </div> -->
      </v-card>
    </center>
    <v-dialog name="dialog-logout-sucesso" v-model="dialogWelcomeNewUser" max-width="300" persistent>
      <v-card>
        <v-card-text class="text-center">
          <v-icon color="success" class="mt-2" size="50">mdi-check-circle</v-icon>
          <h6 class="mt-2">
            Conta criada com sucesso! Seja bem vindo a nossa loja e desejamos
            boas compras!
          </h6>
          <v-row>
            <v-col cols="12">
              <v-progress-linear indeterminate color="secondary">
              </v-progress-linear>
              <span> Carregando dados...</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog name="dialog-validate-email" v-model="dialogValidateEmail" max-width="350" persistent>
      <v-card>
        <v-card-text class="text-center">
          <v-icon color="success" class="mt-2" size="70">mdi-check-circle</v-icon>
          <div class="mt-2">
            <span class="text-subtitle1">
              Para concluir o teu registro, enviamos um código de
              <span class="font-weight-bold">6 dígitos </span> no seguinte
              email:
              <span class="font-weight-bold">{{ user.email }}</span>
            </span>
            <v-row>
              <v-col>
                <v-text-field maxlength="6" input-class="custom-font-size" outlined v-model="user.codigo" dense id="id"></v-text-field>
                <p v-if="countdown > 0">
                  Tempo restante:
                  <span class="font-weight-bold">
                    {{ formatTime(countdown) }}
                  </span>
                </p>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn @click="requestAnother" :disabled="countdown != 0" dense outlined text>
            SOLICITAR OUTRO
          </v-btn>
          <v-btn color="green" class="text-uppercase white--text ml-2" @click="register()" dense>
            Validar EMAIL
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { LOGIN_URL, BASE_URL } from "@/configs/api";

export default {
  data: () => ({
    errorTelefoneJaExiste: "",
    dialogValidateEmail: false,
    countingDown: false,
    countdown: 120, // 2 minutos em segundos
    email: "",
    code: "",
    numbers: Array(6).fill(null),
    dialogValidateEmail: false,
    dialogWelcomeNewUser: false,
    senhaValida: "",
    showpassword: false,
    emailValidationRules: [
      (val) => !!val || "Email é obrigatório",
      (val) => /.+@.+\..+/.test(val) || "Email inválido",
    ],
    step: 1,
    user: {
      name: "",
      telefone: "",
      email: "",
      password1: "",
      password2: "",
    },
    dialogResetPassword: false,
    overlay: false,
    dialog: false,
    showpassword: false,
    valid: true,
    alert: {
      text: "",
      type: "",
      text2: "",
      type2: "",
    },
    utilizador: {},
    user_roles: [],
    roles: {},
    sobrenome: "",
    dialogTermo: true,
    aceitar_termo: false,
    loading: false,
  }),
  computed: {},
  created() {
    this.startCountdown(); // Inicia o contador automaticamente ao criar o componente
  },
  mounted() {},
  methods: {
    showDialogValidateEmail() {
      this.dialogValidateEmail = true;
      this.sendEmailCode();
    },
    sendEmailCode() {
      this.loading = true;
      let url = `${BASE_URL}/user/novo/sendCodigo`;
      axios
        .post(url, this.user)
        .then((response) => {
          this.loading = false;
          // console.log(response.data)
          if (response.data.status == 200) {
            this.dialogValidateEmail = true;
            // setTimeout(() => {

            // }, 100);
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            // O servidor respondeu com um código de status fora do intervalo 2xx
            console.log("Erro de resposta do servidor:");
            console.log("Status:", error.response.status);
            console.log("Dados de erro:", error.response.data.message);
            this.dialogValidateEmail = false;
            this.errorTelefoneJaExiste = error.response.data.message;
            this.alert.type = "error";
          }
        });
    },
    startCountdown() {
      this.countingDown = true;
      const countdownInterval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(countdownInterval);
        }
      }, 1000);
    },
    onDialogClose(value) {
      if (!value) {
        // Resetar o contador quando o diálogo for fechado
        this.countingDown = false;
        this.countdown = 120;
      }
    },
    requestAnother() {
      this.countdown = 120; // Reiniciar o contador
      this.startCountdown(); // Iniciar o contador novamente
      this.sendEmailCode();
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
    },
    validarSenha() {
      const senha = this.user.password1;
      const senhaValida =
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(senha);
      this.senhaValida = senhaValida;
    },
    checkPassword() {
      // let user = this.user
      let password1 = this.user.password1;
      let password2 = this.user.password2;
      return (
        password1 != password2 && password1.length < 9 && password2.length < 9
      );
      // || password1.length == 0 || password2.length == 0
    },

    register() {
      this.loading = true;
      let url = `${BASE_URL}/user/novo`;
      this.user.password2 = this.user.password1;
      this.user.password = this.user.password1;
      axios
        .post(url, this.user)
        .then((response) => {
          console.log("SUCESSO: " + response.data);
          if (response.data.message) {
            // this.alert.text = response.data.message;
            // this.alert.type = "success";
            // localStorage.setItem("EMAIL_CLIENTE", this.user.email);
            // localStorage.setItem("PASSWORD_CLIENTE", this.user.password1);
            setTimeout(() => {
              this.loading = false;
            }, 2000);

            setTimeout(() => {
              this.dialogValidateEmail = false;
              this.dialogWelcomeNewUser = true;
            }, 2500);

            setTimeout(() => {
              this.dialogWelcomeNewUser = false;
            }, 6000);

            this.login();
          }
        })
        .catch((error) => {
          console.log("ERRO: " + error);
          this.alert.text = "Não foi possivel establecer ligação!";
        });
      // setTimeout(() => {
      //     this.loading = false;
      // }, 2000);
    },
    invalidEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.email)) {
        return true;
      } else {
        return false;
      }
    },
    resetPassword() {
      this.loading = true;
      let email = this.email;
      let formData = new FormData();
      formData.append("email", email);

      axios
        .post("/forgot-password-reset", formData)
        .then((response) => {
          setTimeout(() => {
            this.loading = false;
            this.alert.text2 =
              "Foi enviado o link de recuperação da senha no seu email!";
            this.alert.type2 = "success";
            this.email = "";
          }, 1000);
        })
        .catch((error) => {
          this.alert.text2 = error.response.data.errors;
          this.alert.type2 = "error";
          this.loading = false;
          this.dialogResetPassword = true;
        });
    },
    showDialogResetPassword() {
      this.dialogResetPassword = true;
    },

    getUserAuth: function () {
      axios.get("/user").then((response) => {
        this.user = response.data;
      });
    },
    aceitar() {
      this.dialogTermo = false;
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    async login() {
      // this.loading = true;
      await axios.get(`${LOGIN_URL}/sanctum/csrf-cookie`);
      let url = `${LOGIN_URL}/user/login`;
      axios
        .post(url, this.user)
        .then((response) => {
          if (response.data.token) {
            localStorage.setItem("TOKEN", response.data.token);
            let userLogado = JSON.stringify(response.data);
            localStorage.setItem("userLogado", userLogado);
            setTimeout(() => {
              this.$router.push({
                name: "Home",
              });
            }, 6000);
            setTimeout(() => {
              window.location.reload();
            }, 8500);
          } else {
            this.alert.text = "Por favor verifica os teus dados de acesso!";
          }
        })
        .catch((error) => {
          this.alert.text = "Não foi possivel establecer ligação!";
        });
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
  },
};
</script>

<style scoped>
.custom-font-size {
  font-size: 50px !important;
}

.transicao-btn-enter-active,
.transicao-btn-leave-active {
  transition: opacity 0.5s;
}

.transicao-btn-enter,
.transicao-btn-leave-to {
  opacity: 0;
}

.background-image {
  background-image: url("https://www.pngitem.com/pimgs/m/522-5229044_e-commerce-store-png-transparent-png.png");
  background-size: cover;
  /* adjust as necessary */
  background-position: center center;
  /* adjust as necessary */
  background-repeat: no-repeat;
  /* adjust as necessary */
}

#content {
  margin-top: 70px;
}

.section-title {
  padding-bottom: 0px;
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 170px;
  height: 3px;
  background: #2b42a8;
  bottom: 0;
  left: calc(50% - 50%);
}

.red {
  color: red;
}

.green {
  color: green;
}
</style>
